import { Square, SquareCheckBig } from 'lucide-react';
import { Button } from 'src/components/shad-base/button';

// ----------------------------------------------------------------------

export default function Checkbox({
  selected = false,
  onClick = () => undefined,
  disabled = false
}: {
  selected?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
  disabled?: boolean;
}) {
  return (
    <Button
      onClick={(e) => {
        e.stopPropagation();
        onClick(e);
      }}
      variant="outline"
      size="icon"
      disabled={disabled}
      className="scale-90"
    >
      {selected ? (
        <SquareCheckBig
          className={
            'h-icon w-icon ' +
            (disabled ? 'text-primary/50' : 'text-primary')
          }
        />
      ) : (
        <Square
          className={
            'h-icon w-icon ' + (disabled ? 'text-muted' : '')
          }
        />
      )}
    </Button>
  );
}
