import { gql, useQuery } from '@apollo/client';
import {
  ActiveGoalFragmentFragment,
  GoalSelectorGoalsQueryQuery
} from 'src/__apolloGenerated__/graphql';
import SingleSelect from 'src/components/core/molecules/SingleSelect';

export default function GoalSelector({
  activeGoal: activeGoal,
  setActiveGoal: setActiveGoal,
  disabled = false,
  getIsValidGoal = null,
  prefixText = 'Goal: ',
  disableClear = false,
  loading,
  required
}: {
  activeGoal: ActiveGoalFragmentFragment;
  setActiveGoal: (value: ActiveGoalFragmentFragment) => void;
  disabled?: boolean;
  getIsValidGoal?: (
    branch: GoalSelectorGoalsQueryQuery['goals']['data'][0]
  ) => boolean;
  prefixText?: string;
  loading?: boolean;
  disableClear?: boolean;
  required?: boolean;
}) {
  const { data: goalsData, loading: goalsLoading } =
    useQuery<GoalSelectorGoalsQueryQuery>(
      gql`
        fragment ActiveGoalFragment on GoalObject {
          id
          identifier
          title
          frequency
          baselineStartDate
          baselineEndDate
          targetEndDate
          percentReduction
          branch {
            identifier
            name
          }
        }
        query GoalSelectorGoalsQuery {
          goals {
            data {
              ...ActiveGoalFragment
            }
          }
        }
      `,
      {
        fetchPolicy: 'network-only'
      }
    );
  const goals = goalsData?.goals
    ?.data as ActiveGoalFragmentFragment[];
  const validGoals = goals?.filter((goal) =>
    getIsValidGoal ? getIsValidGoal(goal) : true
  );

  // Goals query puts root branch at the end of the list, so we reverse it
  const reversedGoals = validGoals?.map((goal) => goal).reverse();

  return (
    <SingleSelect
      value={activeGoal?.identifier}
      disabled={disabled}
      required={required}
      setValue={(value) => {
        const goal = goals?.find((goal) => {
          return goal.identifier === value;
        });
        setActiveGoal(goal);
      }}
      loading={loading || goalsLoading}
      placeholder="Select goal..."
      commandEmptyText="No goals found."
      options={reversedGoals?.map((goal) => ({
        key: goal.identifier,
        label: goal.title
      }))}
      prefixText={prefixText}
      renderOption={(goalOption) => {
        const goal = goals?.find((goal) => {
          return goal.identifier === goalOption.key;
        });
        return (
          <div className="flex flex-nowrap items-center">
            <p className="body2">{goalOption.label}</p>
            <p className="body2 ml-sm text-muted">
              {goal?.branch?.name}
            </p>
          </div>
        );
      }}
      disableClear={disableClear}
    />
  );
}
